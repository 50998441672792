// for the home page/ root

require('normalize.css/normalize.css');
require('./assets/styles/index.scss');

document.addEventListener("DOMContentLoaded", () => {
	// const titleTextGroup = document.querySelector("#main #text-container");
	// const title = titleTextGroup.querySelector("#title-text");
	// const subtitle = titleTextGroup.querySelector("#subtitle-text");

	// setTimeout( () => {
	// 	title.classList.remove("invisible");
	// 	subtitle.classList.remove("invisible");
	// }, 1000);

	// const actionGroup = document.querySelector("#main #action-container");
	// const action1 = actionGroup.querySelector("#watch-now");
	// const action2 = actionGroup.querySelector("#listen-now");

	// setTimeout( () => {
	// 	action1.classList.remove("invisible");
	// 	action2.classList.remove("invisible");
	// }, 2000);
});